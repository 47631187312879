import React, { useState } from "react";
import ApplyNowSecondForm from "./ApplyNowSecondPage";
import ApplyNowThirdForm from "./ApplyNowThirdPage";
import ApplyNowThirdForm2 from "./ApplyNowThirdPage2";
import ApplyNowFourthForm from "./ApplyNowFourthPage";
import ApplyNowFifthForm from "./ApplyNowFifthpage";
import ApplyNowForms from "./Form";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useEffect } from "react";
import loaderImage from "../../images/ajax.gif";

const ApplyNowMainComp = ({notify, notifySaveForLater}) => {
  const [step, setStep] = useState(1);
  const [error, setError] = React.useState("");
  const [mainData, setMainData] = React.useState({});
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  const [disbaleBtn, setDisableBtn] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [partialData, setpartialDataModel] = useState(false);
  const [allPartialData, setpartialData] = useState({});
  const [selectedUniversity, setSelectedUniversity] = useState(0);
  const [commonLayoutData, setCommonLayoutData] = useState({});
  const [navigationBack, setNavigation] = useState(false);
  const [associateToken, setAssociateToken] = useState();
  const [partialSaveUrl, setPartialSaveUrl] = useState(null);
  const [dummyToken, setDummyToken] = useState('');
  const [tokenError, setTokenError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = window.location.search.replace("?", '');
    setDummyToken(token);
    if (token) {
      fetchAssociateInfo(token);
    }
  }, [commonLayoutData?.token])

  const submitForm = data => {
    setLoading(true)
    fetch(`${enkiUrl}/smn/registration`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

    .then(function (response) {
      if (response.status === 200) {
        notify();
        setError("");
        setDisableBtn(true);
        setTimeout(() => {
          window.location.href = "/apply-now/completed";
        }, 4000);
      } else {
        setLoading(false)
        return response.json().then(data => setError(data.message));
      }
    })
    .catch(err => {
      setDisableBtn(false);
    })
  };

  const partialDataStore = data => {
    setpartialDataModel(true);
    setpartialData(data);
  };
  const handleClose1 = () => {
    setpartialDataModel(false);
  };

  const tokenErrorclose = () => {
    setTokenError(false);
    window.location.href = "/apply-now";
  };

  const savePartialData = () => {
    setLoading(true)
    let data = {};
    data = { ...allPartialData };
    if (dummyToken) {
      data.data.associate_token = dummyToken;
    }
    fetch(`${enkiUrl}/smn/partial-save`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json()).then((data) => {
        if (data.status == 'SUCCESS') {
          window.history.replaceState(null, null, `?${data.associate_token}`);
          setTimeout(() => {
            window.location.reload()
          }, 4000);
          notifySaveForLater();
          setPartialSaveUrl(data.url);
          setDummyToken(data.associate_token);
          fetchAssociateInfo(data.associate_token);
          setError("");
        } else {
          setError(data.message);
        }
      })
      .catch(err => {
        setpartialDataModel(false);
      })
  }

  async function fetchAssociateInfo(token) {
    const response = await fetch(
      `${enkiUrl}/smn-form/associate/get-info-by-token/` + token
    );
    const responseJson = await response.json();
    if (responseJson.status == 'SUCCESS') {
      handleClose1();
      setError("");
      let partialData = Object.assign({}, responseJson.associate, responseJson.associate.partial_data)
      delete partialData.partial_data;
      partialData.token = token;
      setStep(partialData.page_id);
      if(partialData.not_at_university){
        setNavigation(true);
      }
      setProgressValue(partialData.progress_percentage);
      setCommonLayoutData(partialData);
    }
    else {
      return setTokenError(responseJson.message);
    }
  }

  return (
    <div className='apply-now'>
      {
        (loading) &&
        <>
          <div raw-ajax-busy-indicator="" className="bg_load">
            <img src={loaderImage} className="m-auto" />
          </div>
          <div className="wrapper">
            <div className="inner"></div>
          </div>
        </>
      }
      <h1 className="na-head mb-5">APPLY TO THE SOCIAL MOBILITY NETWORK</h1>
      <p className="text-center text-muted progress-bar-text">{progressValue}% COMPLETE</p>
      <ProgressBar now={progressValue} />
      <Container fluid="md">
        <Row className="mt-3 mb-5 progress-bar-pages text-muted text-center">
          <Col className={navigationBack}>Personal Details</Col>
          <Col className={navigationBack}>Career Interests</Col>
          {
            !navigationBack &&
            <>
              <Col>Eligibility</Col>
              <Col>Equality, Diversity and Inclusion</Col>
            </>
          }
        </Row>
      </Container>
      <Modal show={partialData}>
        <Modal.Header>
          <Modal.Title><h2 className="na-head mb-0">Save For Later</h2></Modal.Title>
        </Modal.Header>
        <Modal.Body>Need a break? We will email you a unique link to complete your assessment at a later time.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>Close</Button>
          <Button variant="primary ml-1" onClick={() => savePartialData()}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={tokenError} style={{ zIndex: 1500 }}>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>{tokenError}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={tokenErrorclose}>Close</Button>
        </Modal.Footer>
      </Modal>

      {step === 1 && (
        <ApplyNowForms
          setStep={setStep}
          setErr={setError}
          setProgressValue={setProgressValue}
          partialDataStore={partialDataStore}
          setNavigation={setNavigation}
          selectedUniversity={selectedUniversity}
          setMainData={setMainData}
          setCommonLayoutData={setCommonLayoutData}
          commonLayoutData={commonLayoutData}
          mainData={mainData}
        />
      )
      }
      {step === 2 && (
        <ApplyNowSecondForm
          setStep={setStep}
          setProgressValue={setProgressValue}
          partialDataStore={partialDataStore}
          setSelectedUniversity={setSelectedUniversity}
          setCommonLayoutData={setCommonLayoutData}
          commonLayoutData={commonLayoutData}
          setMainData={setMainData}
          mainData={mainData}
        />
      )}
      {step === 3 && (
        <ApplyNowThirdForm
          setStep={setStep}
          navigation={navigationBack}
          setNavigation={setNavigation}
          partialDataStore={partialDataStore}
          setCommonLayoutData={setCommonLayoutData}
          setProgressValue={setProgressValue}
          commonLayoutData={commonLayoutData}
          setMainData={setMainData}
          mainData={mainData}
        />
      )}
      {step === 4 && (
        <ApplyNowThirdForm2
          setStep={setStep}
          setNavigation={navigationBack}
          partialDataStore={partialDataStore}
          setCommonLayoutData={setCommonLayoutData}
          setProgressValue={setProgressValue}
          commonLayoutData={commonLayoutData}
          submitForm = {submitForm}
            setMainData={setMainData}
          mainData={mainData}
          err={error}
        />
      )}
      {step === 5 && (
        <ApplyNowFourthForm
          setStep={setStep}
          setProgressValue={setProgressValue}
          partialDataStore={partialDataStore}
          setCommonLayoutData={setCommonLayoutData}
          commonLayoutData={commonLayoutData}
          setMainData={setMainData}
          mainData={mainData}
        />
      )}
      {step === 6 && (
        <ApplyNowFifthForm
          setStep={setStep}
          setProgressValue={setProgressValue}
          partialDataStore={partialDataStore}
          submitForm={submitForm}
          setLoading={setLoading}
          loading={loading}
          err={error}
          setCommonLayoutData={setCommonLayoutData}
          commonLayoutData={commonLayoutData}
          setMainData={setMainData}
          mainData={mainData}
        />
      )}
    </div>
  );
};

export default ApplyNowMainComp;