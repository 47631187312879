import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

const ApplyNowSecondForm = ({
  setStep,
  err,
  setProgressValue,
  setCommonLayoutData,
  commonLayoutData,
  partialDataStore
}) => {
  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState(0);
  const [colleges, setColleges] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [degrees, setDegrees] = useState();
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  const [error, setError] = useState("");
  const [studentData, setStudentData] = useState("");
  const { register, handleSubmit, errors, getValues } = useForm();
  const [currentYearCalculation, setCurrentYearCalculation] = useState(null);

  useEffect(() => {
    getDegrees();
    fetchUniversities();
    fetchUniversityCourses();
    async function fetchUniversityData() {
      if (
        selectedUniversity === 30 ||
        selectedUniversity === 46 ||
        selectedUniversity === 57 ||
        selectedUniversity === 111
      ) {
        const response = await fetch(
          `${enkiUrl}/universities/get-info/${selectedUniversity}`
        );
        const responseJson = await response.json();
        if (selectedUniversity === 30 || selectedUniversity === 57) {
          setColleges(responseJson.university.college);
        } else if (selectedUniversity === 46 || selectedUniversity === 111) {
          setCampuses(responseJson.university.campus);
        }
      }
    }
    fetchUniversityData();
  }, [selectedUniversity]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  //for save for later campus values setting 
  useEffect(() => {
    if (commonLayoutData?.university_id) {
      setSelectedUniversity(Number(commonLayoutData.university_id));
    }
    fetchUniversities();
    fetchUniversityCourses();
    async function fetchUniversityData() {
      if (
        commonLayoutData.university_id == 30 ||
        commonLayoutData.university_id == 46 ||
        commonLayoutData.university_id == 57 ||
        commonLayoutData.university_id == 111
      ) {
        const response = await fetch(
          `${enkiUrl}/universities/get-info/${commonLayoutData.university_id}`
        );
        const responseJson = await response.json();
        if (commonLayoutData.university_id == 30 || commonLayoutData.university_id == 57) {
          setColleges(responseJson.university.college);
        } else if (commonLayoutData.university_id == 46 || commonLayoutData.university_id == 111) {
          setCampuses(responseJson.university.campus);
        }
      }
    }
    fetchUniversityData();
  }, [commonLayoutData.university_id])

  useEffect(() => {
    currentYearCal();
  }, []);
 
  // Function to calculate the academic year
  const currentYearCal = () => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // Getting the current month (1-based: January = 1, December = 12)
    // Determine the academic year based on whether it's September or later
    const yearToDisplay = currentMonth >= 9 ? today.getFullYear() : today.getFullYear() - 2;
    setCurrentYearCalculation(yearToDisplay);
  };

  const fetchUniversityCourses = async () => {
    const response = await fetch(`${enkiUrl}/course/get-university-courses`);
    const responseJson = await response.json();
    let data = responseJson.universityCourses;
    setCourses(data);
  };

  const getDegrees = async () => {
    var response = await fetch(`${enkiUrl}/degree/get-degrees`);
    const responseJson = await response.json();
    let data = responseJson.degrees;
    setDegrees(data);
  };

  async function fetchUniversities() {
    const response = await fetch(
      `${enkiUrl}/universities/get-active-universities`
    );
    const responseJson = await response.json();
    let universities = responseJson.universities;
    universities.sort((a, b) => a.name > b.name);
    setUniversities(universities);
  }

  useEffect(() => {
    setStudentData(commonLayoutData?.student_type)
    if (commonLayoutData?.university_id) commonLayoutData.university_id = Number(commonLayoutData.university_id)
    if (commonLayoutData?.university_course_id) commonLayoutData.university_course_id = Number(commonLayoutData.university_course_id)
    if (commonLayoutData?.year_of_study) commonLayoutData.year_of_study = Number(commonLayoutData.year_of_study)
    if (commonLayoutData?.course_duration) commonLayoutData.course_duration = Number(commonLayoutData.course_duration)
    if (commonLayoutData?.degree_id) commonLayoutData.degree_id = Number(commonLayoutData.degree_id)
  }, [commonLayoutData]);

  const saveForLater = (pageId, progressPercent) => {
    let data = { ...data, ...commonLayoutData };
    if (commonLayoutData.page_id > pageId) {     //save for later move to last updated page
      data.page_id = commonLayoutData.page_id;
      data.progress_percentage = commonLayoutData.progress_percentage;
    }
    else {
      data.page_id = pageId;
      data.progress_percentage = progressPercent;
    }
    partialDataStore({ data });
  }

  const submitData = data => {
    if (
      data["year_of_study"] === "0" ||
      data["course_duration"] === "0" ||
      data["university_id"] === "Select University" ||
      data["expected_start_date"] === "Select Opening Year" ||
      data["university_course_id"] === "Select Course" ||
      data["study_type"] == ""
    ) {
      if (data["year_of_study"] === "") {
        setError("Please select year of study");
      }
      if (data["university_id"] === "Select University") {
        setError("Please select university");
      }
      if (data["course_duration"] === "") {
        setError("Please select course length");
      }
      if (data["expected_start_date"] === "Select Opening Year") {
        setError("Please select expected start date");
      }
      if (data["university_course_id"] === "Select Course") {
        setError("Please select course");
      }
      if (data["study_type"] == "") {
        setError("Please select studying type");
      }
      return;
    }
    if (data["year_of_study"] > data["course_duration"]) {
      setError("Year of study is longer than Course Duration");
      return;
    }
    setCommonLayoutData({ ...commonLayoutData, ...data });
    setStep(3);
    setProgressValue("30");
  };

  return (
    <div>
      <h2 className="na-head mb-4">Personal Details</h2>
      <h4>UNIVERSITY INFORMATION</h4>
      <Form onSubmit={handleSubmit(submitData)}>
        <Form.Label>
          University <span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          ref={register({ required: true })}
          name={"university_id"}
          as="select"
          onChange={e => {
            setSelectedUniversity(Number(e.target.value))
            setCommonLayoutData({ ...commonLayoutData, ...getValues() })
          }}
        >
          <option value="" selected>Select university</option>
          {universities &&
            universities.length > 0 &&
            universities.map(university => (
              <option
                value={Number(university.id)}
                selected={
                  university.id === Number(commonLayoutData?.university_id)
                }
              >
                {university.name}
              </option>
            ))}
        </Form.Control>
        {errors.university_id && (
          <p className="mb-0 text-danger font-weight-bold">Please select your university</p>
        )}

        {(selectedUniversity == 30 || selectedUniversity == 57) && (
          <div>
            <Form.Label>
              College <span className="required-text">*</span>
            </Form.Label>
            <Form.Control
              ref={register({ required: true })}
              name="college_id"
              as="select"
              onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
              defaultValue={
                commonLayoutData !== null ? commonLayoutData.college_id : ""
              }
            >
              <option value="" selected>Select College</option>
              {colleges &&
                colleges.length > 0 &&
                colleges.map(college => (
                  <option
                    value={college.id}
                    selected={
                      college.id === Number(commonLayoutData?.college_id)
                    }
                  >
                    {college.name}
                  </option>
                ))}
            </Form.Control>
            {errors.college_id && (
              <p className="mb-0 text-danger font-weight-bold">Please select your college</p>
            )}
          </div>
        )}
        {(selectedUniversity == 46 || selectedUniversity == 111) && (
          <div>
            <Form.Label>
              Campus <span className="required-text">*</span>
            </Form.Label>
            <Form.Control
              ref={register({ required: true })}
              name="campus_id"
              as="select"
              onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
              defaultValue={
                commonLayoutData !== null ? commonLayoutData.campus_id : ""
              }
            >
              <option value="" selected>Select Campus</option>
              {campuses &&
                campuses.length > 0 &&
                campuses.map(campus => (
                  <option
                    value={campus.id}
                    selected={
                      campus.id === Number(commonLayoutData?.campus_id)
                    }
                  >
                    {campus.name}
                  </option>
                ))}
            </Form.Control>
            {errors.campus_id && (
              <p className="mb-0 text-danger font-weight-bold">Please select your campus</p>
            )}
          </div>
        )}

        <Form.Label>
          University Course <span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          ref={register({ required: true })}
          name="university_course_id"
          as="select"
          onChange={e => {
            setSelectedCourse(Number(e.target.value))
            setCommonLayoutData({ ...commonLayoutData, ...getValues() })
          }}
          defaultValue={
            commonLayoutData !== null
              ? commonLayoutData.university_course_id
              : ""
          }
        ><option value="" selected>Select course</option>
          {courses &&
            courses.length > 0 &&
            courses.map(course => (
              <option
                value={Number(course.id)}
                selected={
                  course.id === Number(commonLayoutData?.university_course_id)
                }
              >
                {course.name}
              </option>
            ))}
        </Form.Control>
        {errors.university_course_id && (
          <p className="mb-0 text-danger font-weight-bold">Please select your course</p>
        )}
        {selectedCourse == 96 && (
          <div>
            <Form.Label>
              Please enter your course <span className="required-text">*</span>
            </Form.Label>
            <Form.Control
              defaultValue={
                commonLayoutData !== null
                  ? commonLayoutData.university_course_other_text
                  : ""
              }
              type="text"
              ref={register({ required: true })}
              onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
              name="university_course_other_text"
            />
            {errors.university_course_other_text && (
              <p className="mb-0 text-danger font-weight-bold">Please enter your course</p>
            )}
          </div>
        )}
        <Form.Label>
          Degree Type <span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          as="select"
          ref={register({ required: true })}
          name="degree_id"
          onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
        >
          <option value="" selected disabled>
            Select degree type
          </option>
          {degrees &&
            degrees.length > 0 &&
            degrees.map(degree => (
              <option
                value={Number(degree.id)}
                selected={degree.id === Number(commonLayoutData?.degree_id)}
              >
                {degree.name}
              </option>
            ))}
        </Form.Control>
        {errors.degree_id && (
          <p className="mb-0 text-danger font-weight-bold">Please select your degree</p>
        )}

        <Form.Label>
          Year of study as of September {currentYearCalculation} <span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          value={commonLayoutData?.year_of_study}
          as="select"
          ref={register({ required: true })}
          name="year_of_study"
          onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
        >
          <option value="" selected disabled>
            Select year of study
          </option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
        </Form.Control>
        {errors.year_of_study && (
          <p className="mb-0 text-danger font-weight-bold">Please select your year of study</p>
        )}

        <Form.Label>
          Length of undergraduate course (years) <span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          value={commonLayoutData?.course_duration}
          as="select"
          ref={register({ required: true })}
          name="course_duration"
          onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
        >
          <option value="" selected disabled>
            Select length of course
          </option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
        </Form.Control>
        {errors.course_duration && (
          <p className="mb-0 text-danger font-weight-bold">Please select your length of undergraduate</p>
        )}

        <Form.Label>
          Are you studying full time or part time? <span className="required-text">*</span>
        </Form.Label>
        <Form.Group>
          <Form.Check
            ref={register({ required: true })} value="FULL_TIME" id="fullTime" htmlFor="fullTime"
            onClick={e => {
              setStudentData("FULL_TIME")
              setCommonLayoutData({ ...commonLayoutData, ...getValues() })
            }}
            type="radio" label="I am studying full time" name="student_type"
            checked={studentData == "FULL_TIME" ? true : false}
          />
          <Form.Check
            ref={register({ required: true })} value="PART_TIME" id="partTime" type="radio"
            onClick={e => {
              setStudentData("PART_TIME")
              setCommonLayoutData({ ...commonLayoutData, ...getValues() })
            }}
            name="student_type" htmlFor="partTime" label="I am studying part time"
            checked={studentData == "PART_TIME" ? true : false}
          />
          {errors.student_type && (
            <p className="mb-0 text-danger font-weight-bold">Please select study type</p>
          )}
        </Form.Group>

        {error !== "" && <div className="fail-text">{error}</div>}

        {err !== "" && <div className="fail-text">{err}</div>}

        <div className="row my-5">
          <div className="col-md-2 my-auto text-md-left">
            <button className="btn btn-light btn-md" type="button" onClick={() => { setStep(1); setProgressValue(0) }}>BACK</button>
          </div>
          <div className="col-md-10 text-md-right">
            <button className="btn btn-light btn-md" onClick={(e) => { e.preventDefault(); saveForLater(2, 20); }}>SAVE FOR LATER</button>
            <button className="btn btn-primary btn-md ml-3" type="submit">NEXT</button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ApplyNowSecondForm;