import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Radio from '@material-ui/core/Radio';
import SocialMobilityNetworkMemberUserAgreement from "../../documents/SocialMobilityNetworkMemberUserAgreement.pdf" ;

const ApplyNowThirdForm2 = ({ setStep, err, setProgressValue, navigation, setNavigation, partialDataStore, setCommonLayoutData,
  commonLayoutData,submitForm, }) => {
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm({ mode: "onChange" });
  const [careerInterests, setCareerInterests] = useState([]);
  const [unmappedCareerInterests, setUnmappedCareerInterests] = useState([]);
  const [otherCareerInterest, setOtherCareerInterest] = useState([]);
  const [otherCareerArea, setOtherCareerArea] = useState('');
  const [flag, setFlag] = useState(false);
  const [careerArea, setCareerArea] = useState([]);
  const [eligibility, setEligibility] = useState(false);
  const [dataPolicy, setDataPolicy] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCareerStreamData2();
  }, [])

  useEffect(() => {
    var career_area = [];
    if (commonLayoutData.careerInterestSelected != undefined && commonLayoutData.careerInterestSelected.length > 0) {
      commonLayoutData.careerInterestSelected.forEach((element, index) => {
        career_area[element.id] = element.interestLevel;
      })
      setCareerArea(career_area);
      setOtherCareerArea(commonLayoutData?.other_career_area)
    }
    if(commonLayoutData.flag != undefined) {
      setFlag(commonLayoutData.flag);
    }
  }, [commonLayoutData.careerInterestSelected]);

  const fetchCareerStreamData2 = async () => {
    const response = await fetch(
      `${enkiUrl}/career-interest/get-active-career-interest-by-stream/${commonLayoutData?.first_choice}`
    )
    const data = await response.json()
    setCareerInterests(data.careerInterest);
    setUnmappedCareerInterests(data.unmappedCareerInterest);
    setOtherCareerInterest(data.otherCareerInterest);
    if (commonLayoutData.careerInterestSelected == undefined || commonLayoutData.careerInterestSelected.length == undefined) {
      var career_area = [];
      if (data.careerInterest != undefined && data.careerInterest.length > 0) {
        data.careerInterest.forEach((element, index) => {
          career_area[element.id] = "NO_INTEREST"
        })
      }
      if (data.unmappedCareerInterest != undefined && data.unmappedCareerInterest.length > 0) {
        data.unmappedCareerInterest.forEach((element, index) => {
          career_area[element.id] = "NO_INTEREST"
        })
      }
      if (data.otherCareerInterest != undefined) {
        career_area[data.otherCareerInterest.id] = "NO_INTEREST"
      }
      setCareerArea(career_area)

      commonLayoutData.careerInterestSelected = [];
      career_area.forEach((value, key) => {
        if (key == otherCareerInterest.id) {
          if (value != 'NO_INTEREST') {
            if (otherCareerArea === undefined || otherCareerArea === "" || otherCareerArea === null) {
              errors.push("You selected \"other\" as a career area. Please give more information in the text box below.");
            } else {
              commonLayoutData.otherCareerArea = otherCareerArea;
            }
          } else if (value == 'NO_INTEREST') {
            if (otherCareerArea != undefined) {
              otherCareerArea = '';
            }
          }
        }
        commonLayoutData.careerInterestSelected.push({
          id: key,
          interestLevel: value
        });
      });
    }
  }

  const saveForLater = (pageId, progressPercent) => {
    let data = { ...data, ...commonLayoutData };

    //save for later move to last updated page
    if (commonLayoutData.page_id > pageId) {
      data.page_id = commonLayoutData.page_id;
      data.progress_percentage = commonLayoutData.progress_percentage;
    }
    else {
      data.page_id = pageId;
      data.progress_percentage = progressPercent;
    }

    partialDataStore({ data });
  }

  const submitData = (data) => {

    setCommonLayoutData({ ...commonLayoutData });
    if(commonLayoutData.not_at_university == true){
      setProgressValue("100");
      submitForm(commonLayoutData);
    }else{
    setStep(5);
    setProgressValue("60");
    }
  }

  const handleCareerInterestChange = (careerInterestId, e, fieldName) => {
    
    let name = e.target.name;
    let value = e.target.value;
    let any = careerInterestId;
    if (careerInterestId == otherCareerInterest.id) {
      if (value != 'NO_INTEREST') {
        if (otherCareerArea === undefined || otherCareerArea === "" || otherCareerArea === null) {
          errors.push("You selected \"other\" as a career area. Please give more information in the text box below.");
        } else {
          commonLayoutData.otherCareerArea = otherCareerArea;
        }
      } else if (value == 'NO_INTEREST') {
        if (otherCareerArea != undefined) {
          otherCareerArea = '';
        }
      }
    }
    if (fieldName == 'otherCareerArea') {
      setOtherCareerArea(value);
      commonLayoutData["other_career_area"]= value;
    } else {
      var career_area = [...careerArea];
      career_area[careerInterestId] = value;
      setCareerArea(career_area);

      var index = commonLayoutData.careerInterestSelected.findIndex(item => item.id === careerInterestId);
      commonLayoutData.careerInterestSelected[index] = {
      id: careerInterestId,
      interestLevel: value
      };
    }
  }

  const otherText = (e, id) => {

    let value = e.target.value;
    const career_area = [...careerArea];
    career_area[id] = e.target.value;
    if (value === 'STRONG_INTEREST') {
      setFlag(true);
      commonLayoutData.flag = true;
    } else if (value === 'KEEN_TO_EXPLORE_FURTHER') {
      setFlag(true);
      commonLayoutData.flag = true;
    } else {
      setFlag(false);
      commonLayoutData.flag = false;
      setOtherCareerArea('');
      // setOtherCareerArea(value)
    }
    setCareerArea(career_area);

    var index = commonLayoutData.careerInterestSelected.findIndex(item => item.id === id);
      commonLayoutData.careerInterestSelected[index] = {
      id: id,
      interestLevel: value
    };
  }
  return (
    <div>
      <p className="mb-5">Let us know any additional career interests you may have below.</p>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="row border-bottom border-light">
          <div className="col-6"><h2 className="na-head">Secondary Career Interests</h2></div>
          <div className="col-6">
            <div className="row text-center">
              <div className="col-4">
                <p className='mt-3 mb-0 font-weight-bold'>Strong Interest</p>
              </div>
              <div className="col-4">
                <p className='mt-3 mb-0 font-weight-bold'>Keen To Explore</p>
              </div>
              <div className="col-4">
                <p className='mt-3 mb-0 font-weight-bold'>No Interest</p>
              </div>
            </div>
          </div>
        </div>
        {
          careerInterests && careerInterests.map((data, index) => (
            <div className="row border-bottom border-light" key={index}>
              <div className="col-6">
                <p class="mt-2 mb-0">{data.name}</p>
              </div>
              <div className="col-6">
                <div className="row d-flex justify-content-around">
                  <Radio name={data.id + "_career_interest"} type="radio" color="primary" className="rbs"
                    value="STRONG_INTEREST" onChange={(e) => handleCareerInterestChange(data.id, e)}
                    checked={careerArea[data.id] == "STRONG_INTEREST"} />
                  <Radio name={data.id + "_career_interest"} type="radio" color="primary" className="rbs"
                    value="KEEN_TO_EXPLORE_FURTHER" onChange={(e) => handleCareerInterestChange(data.id, e)}
                    checked={careerArea[data.id] == "KEEN_TO_EXPLORE_FURTHER"} />
                  <Radio name={data.id + "_career_interest"} type="radio" color="primary" className="rbs"
                    value="NO_INTEREST" onChange={(e) => handleCareerInterestChange(data.id, e)}
                    checked={careerArea[data.id] == "NO_INTEREST"} />
                </div>
              </div>
            </div>
          ))
        }

        {
          unmappedCareerInterests && unmappedCareerInterests.map((data, index) => (
            <div className="row border-bottom border-light" key={index}>
              <div className="col-6">
                <p class="mt-2 mb-0">{data.name}</p>
              </div>
              <div className="col-6">
                <div className="row d-flex justify-content-around">
                  <Radio name={data.id + "_career_interest"} type="radio" color="primary" className="rbs"
                    checked={careerArea[data.id] == "STRONG_INTEREST"}
                    value="STRONG_INTEREST" onChange={(e) => handleCareerInterestChange(data.id, e)} />
                  <Radio name={data.id + "_career_interest"} type="radio" color="primary" className="rbs"
                    checked={careerArea[data.id] == "KEEN_TO_EXPLORE_FURTHER"}
                    value="KEEN_TO_EXPLORE_FURTHER" onChange={(e) => handleCareerInterestChange(data.id, e)} />
                  <Radio name={data.id + "_career_interest"} type="radio" color="primary" className="rbs"
                    checked={careerArea[data.id] == "NO_INTEREST"}
                    value="NO_INTEREST" onChange={(e) => handleCareerInterestChange(data.id, e)} />
                </div>
              </div>
            </div>
          ))
        }
        {
          // otherCareerInterest &&
          <div className="row border-bottom border-light" >
            <div className="col-6">
              {
                (flag == true) &&
                <input type="text" className="form-text pt-2" name="otherCareerArea" value={otherCareerArea || ''} onChange={(e) => handleCareerInterestChange('', e,'otherCareerArea')} required />
              }
              {
                (flag == true) &&
                <span className="bar"></span>
              }
              <p class="mt-2">{otherCareerInterest.name}</p>
            </div>
            <div className="col-6">
              <div className="row d-flex justify-content-around ">
                <Radio name={otherCareerInterest.id + "_career_interest"} type="radio" color="primary" className="rbs"
                  value="STRONG_INTEREST" onChange={(e) => otherText(e, otherCareerInterest.id)}
                  checked={careerArea[otherCareerInterest.id] == "STRONG_INTEREST"} />
                <Radio name={otherCareerInterest.id + "_career_interest"} type="radio" color="primary" className="rbs"
                  value="KEEN_TO_EXPLORE_FURTHER" onChange={(e) => otherText(e, otherCareerInterest.id)}
                  checked={careerArea[otherCareerInterest.id] == "KEEN_TO_EXPLORE_FURTHER"} />
                <Radio name={otherCareerInterest.id + "_career_interest"} type="radio" color="primary" className="rbs"
                  value="NO_INTEREST" onChange={(e) => otherText(e, otherCareerInterest.id)}
                  checked={careerArea[otherCareerInterest.id] == "NO_INTEREST"} />
              </div>
            </div>
          </div>
        }

       { commonLayoutData.not_at_university &&
       <Form.Group>
          <div className="row">
            <div className="col-md-12">
              <Form.Check
                ref={register({ required: true })}
                name="form1_data_policy"
                id="form1_data_policy"
                htmlFor="form1_data_policy"
                label="By ticking this box, I agree to the "
                feedback="You must agree before submitting."
                onChange={
                  () => {
                    setDataPolicy(!dataPolicy)
                    setCommonLayoutData({ ...commonLayoutData });
                  }
                }
                checked={dataPolicy}
                style={{ display: "inline-block" }}
              />
              <a
                href={SocialMobilityNetworkMemberUserAgreement+"#toolbar=0"}
                target="_blank"
                className="checkbox-link ml-2">
                Social Mobility Network User Agreement and Data Protection Policy.
              </a>
            </div>
          </div>
        </Form.Group>
        }
        {errors.form1_data_policy && (
          <p className="mb-0 text-danger font-weight-bold">Required</p>
        )}
        {
          error !== "" && <div className="fail-text">{error}</div>
        }
         {
          err !== "" && <div className="fail-text">{err}</div>
        }
        <div className="row my-5">
          <div className="col-md-2 my-auto text-md-left">
            <button className="btn btn-light btn-md" type="button" onClick={() => {
              if (setNavigation) {
                setStep(3); setProgressValue(50)
              } else {
                setStep(3); setProgressValue(30)
              }
            }}>BACK</button>
          </div>
          <div className="col-md-10 text-md-right">
            <button onClick={(e) => {
              e.preventDefault();
              commonLayoutData.not_at_university === true ? saveForLater(4,80) : saveForLater(4,40)
            }} className="btn btn-light btn-md">SAVE FOR LATER</button>
            <Button className="btn btn-primary btn-md ml-3" type="submit">
              {commonLayoutData.not_at_university == true ? "SUBMIT" : "NEXT" }
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default ApplyNowThirdForm2;