import React, { useState, useEffect } from 'react';
import { Form, Accordion, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Radio from '@material-ui/core/Radio';
const ApplyNowThirdForm = ({ setStep, setProgressValue,navigation, setNavigation, partialDataStore, setCommonLayoutData,
  commonLayoutData, }) => {
  const [selectedVal1, setSelectedVal1] = useState(null);
  const [selectedVal2, setSelectedVal2] = useState(null);
  const [careerStreamData, setCareerStreamData] = useState([]);
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  const [error, setError] = useState("");
  const { register, handleSubmit, errors } = useForm({ mode: "onChange" });
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCareerStreamData();
  }, [])
  useEffect(() => {
    setSelectedVal1(commonLayoutData?.first_choice || null)
    setSelectedVal2(commonLayoutData?.second_choice || null)
  }, [commonLayoutData]);
  const fetchCareerStreamData = async () => {
    const response = await fetch(
      `${enkiUrl}/career-streams/get-formone-active-career-stream`
    )
    const data = await response.json()
    setCareerStreamData(data.careerStreams);
  }
  const saveForLater = (pageId, progressPercent) => {
    let data = { ...data, ...commonLayoutData };
    if (commonLayoutData.page_id > pageId) {  //save for later move to last updated page
      data.page_id = commonLayoutData.page_id;
      data.progress_percentage = commonLayoutData.progress_percentage;
    }
    else {
      data.page_id = pageId;
      data.progress_percentage = progressPercent;
    }
    partialDataStore({ data });
  }
  const submitData = (data) => {
    if (selectedVal1 === null && selectedVal2 === null) {
      setError("Please select a first and second preference")
      return
    } else if (selectedVal1 === null) {
      setError("Please select a first preference")
      return
    } else if (selectedVal2 === null) {
      setError("Please select a second preference")
      return
    }
    const formData = {
      "first_choice": selectedVal1,
      "second_choice": selectedVal2,
    }
    if(commonLayoutData.not_at_university == true){
        setProgressValue("80");
        setStep(4);
      }else{
      setStep(4);
      setProgressValue("40");
      }
    setCommonLayoutData({ ...commonLayoutData, ...formData });
  }
  return (
    <div>
      <h2 className="na-head mb-2">Career Interests</h2>
      <p className="mb-5">Let us know which career sector you are mostly interested in. If you are not sure at this stage, or if none of the below apply to you, please select 'Exploring'.</p>
      <Form onSubmit={handleSubmit(submitData)}>
        <div className="row border-bottom border-light">
          <div className="col-6">
            <h2 className="na-head">Primary Career Interests</h2>
          </div>
          <div className="col-6">
            <div className="row text-center">
              <div className="col-6">
                <p className='mt-3 mb-0 font-weight-bold'>First Preference</p>
              </div>
              <div className="col-6">
                <p className='mt-3 mb-0 font-weight-bold'>Second Preference</p>
              </div>
            </div>
          </div>
        </div>
        {
          careerStreamData && careerStreamData.sort((a, b) => a.name.localeCompare(b.name)).map((data, index) => (
            <div className="row border-bottom border-light" key={index}>
              <div className="col-6">
                <Accordion flushdefaultActiveKey="0" flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="mb-0 lhr">{data?.name}</Accordion.Header>
                    <Accordion.Body><p className="mt-1 text-muted txt15">{data?.description}</p></Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-6">
                <div className="row text-center">
                  <div className='col-6'>
                    <Radio
                      className="rbs"                 
                      color="primary"
                      checked={selectedVal1 === Number(data.id)}
                      onChange={(e) => {
                        setSelectedVal1(Number(e.target.value))
                        commonLayoutData["first_choice"] = Number(e.target.value)
                      }}
                      value={data.id}
                      name="radio-button-demo"
                    />
                  </div>
                  <div className='col-6'>
                    <Radio
                      className="rbs"
                      color="primary"
                      checked={selectedVal2 === Number(data.id)}
                      onChange={(e) => {
                        setSelectedVal2(Number(e.target.value))
                        commonLayoutData["second_choice"] = Number(e.target.value)
                      }}
                      value={data.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))
        }
        {
          error !== "" && <div className="fail-text">{error}</div>
        }
        <div className="row my-5">
          <div className="col-md-2 my-auto text-md-left">
            <button className="btn btn-light btn-md" type="button" onClick={() => {
              if (navigation) {
                setStep(1); setProgressValue(0);
              } else {
                setStep(2); setProgressValue(20)
              }
              setNavigation(false)
            }}>BACK</button>
          </div>
          <div className="col-md-10 text-md-right">
            <button onClick={(e) => {
              e.preventDefault();
              commonLayoutData.not_at_university === true ? saveForLater(4,50) : saveForLater(4,30)
            }} className="btn btn-light btn-md">SAVE FOR LATER</button>
            <button className="btn btn-primary btn-md ml-3" type="submit">NEXT</button>
          </div>
        </div>
      </Form>
    </div>
  )
}
export default ApplyNowThirdForm;