import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";

const ApplyNowForms = ({
  setStep,
  setNavigation,
  selectedUniversity,
  setErr,
  setProgressValue,
  setCommonLayoutData,
  commonLayoutData,
  partialDataStore,
}) => {

  const {
    register,
    handleSubmit,
    getValues,
    errors,
  } = useForm();

  const [atUniversity, setAtUniversity] = useState(false);
  const [notAtUniversityYet, setNotAtUniversityYet] = useState(false);
  const [openingYears, setOpeningYears] = useState([]);
  const [references, setReferences] = useState([]);
  const [refer, setRefer] = useState([]);
  const [error, setError] = useState("");
  const [univErrors, setUnivErrors] = useState(false);
  const [selectedReference, setSelectedReference] = useState([]);
  const [referenceList, setReferenceList] = useState([]);
  const [referList, setReferList] = useState([]);
  const [otherReferenceFlag, setOtherReferenceFlag] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const enkiUrl = process.env.GATSBY_ENKI_URL;

  useEffect(() => {
    fetchOpeningYears();
    getReference();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (commonLayoutData || commonLayoutData.token) {
      setCommonLayoutData(commonLayoutData);
      if (commonLayoutData.not_at_university == false) {
        setAtUniversity(true);
        setNotAtUniversityYet(false);
        delete commonLayoutData.opening_year_id;
      } else if (commonLayoutData.not_at_university == true) {
        setAtUniversity(false);
        setNotAtUniversityYet(true);
        delete commonLayoutData.university_email;
        delete commonLayoutData.confirm_university_email;
      }
      if (commonLayoutData.references) {
        showReferences();
      }
      if (commonLayoutData?.references) {
        setSelectedReference(Number(commonLayoutData.references));
        commonLayoutData.references = Number(commonLayoutData.references)
      }
      if (commonLayoutData.other_reference_id) {
        showReferences();
      }
    }
  }, [commonLayoutData]);

  useEffect(() => {
    if (commonLayoutData.not_at_university == "false") {
      commonLayoutData.not_at_university = false
      setAtUniversity(true);
      setNotAtUniversityYet(false);
    } else if (commonLayoutData.not_at_university == "true") {
      commonLayoutData.not_at_university = true
      setAtUniversity(false);
      setNotAtUniversityYet(true);
    }
  }, [commonLayoutData?.not_at_university])

  useEffect(() => {
    otherRefer(commonLayoutData?.references)
  }, [commonLayoutData?.other_reference_id])

  const handleChange = e => {
    let id = e.target.id;
    let name = e.target.name;
    let value = e.target.value;
    if (id == "references") {
      delete commonLayoutData?.other_reference_id
      delete commonLayoutData?.other_reference_text
      setSelectedReference(commonLayoutData?.references)
      showReferences();
      otherRefer(value);
    }
    setCommonLayoutData({ ...commonLayoutData, ...getValues() })
  }
  const otherRefer = (value) => {
    var url = `${enkiUrl}/references/get-all-active-refer/${value}`;
    let referArray = [];
    let refer_list = [];
    const requestOptions = { method: "GET" };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.status == "SUCCESS") {
          refer_list = data.refer;
          let uniqueObjects = {};
          for (let i in refer_list) {
            let objTitle = refer_list[i]["name"];
            uniqueObjects[objTitle] = refer_list[i];
          }
          for (let i in uniqueObjects) {
            referArray.push(uniqueObjects[i]);
          }
          setRefer(referArray);
          setReferList(refer_list);
        }
      });
  }
  const getReference = () => {
    var url = `${enkiUrl}/references/get-active-references`;
    let references_list = [];
    let referencesArray = [];
    const requestOptions = { method: "GET" };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.status == "SUCCESS") {
          references_list = data.references;
          let uniqueObject = {};
          for (let i in references_list) {
            let objTitle = references_list[i]["name"];
            uniqueObject[objTitle] = references_list[i];
          }
          for (let i in uniqueObject) {
            referencesArray.push(uniqueObject[i]);
          }
          setReferences(referencesArray);
          setReferenceList(references_list);
          if (commonLayoutData?.references) {
            const filteredValue = referencesArray.filter((value, index) => value?.associate_reference_id === Number(commonLayoutData?.references))
            const selectedValues = references_list.filter((value, index) => value?.associate_reference_id == filteredValue?.[0]?.associate_reference_id)
            if (selectedValues.length > 0 && selectedValues[0]?.reference_category_id != null)
              setOtherReferenceFlag(true);
            else setOtherReferenceFlag(false);
            setSelectedCategory(selectedValues);
          }
        }
      });
  }
  const showReferences = () => {
    setOtherReferenceFlag(false);
    const filteredValue = refer.filter((value) => value?.other_reference_id === Number(commonLayoutData?.refer))
    const selectedValues = referList.filter((value) => value?.other_reference_id == filteredValue?.[0]?.other_reference_id)
    if (selectedValues.length > 0 && selectedValues[0]?.reference_category_id != null) {
      setOtherReferenceFlag(true);
    }
    else {
      setOtherReferenceFlag(false);
    }
    setSelectedCategory(selectedValues);
  };

  const fetchOpeningYears = async () => {
    const response = await fetch(
      `${enkiUrl}/university-opening-years/get-active-opening-years`
    );
    const responseJson = await response.json();
    let data = responseJson.opening_years;
    setOpeningYears(data);
  };

  // Check university duplicate email 
  const duplicateUniversityEmail = university_email => {
    setError(null);
    var url = `${enkiUrl}/form/duplicate-university-email/` + university_email;
    var requestOptions = {
      method: "GET",
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.status == "SUCCESS") {
          if (data.is_partial) {
            if (data.is_duplicate == "YES" && data.is_partial == "YES") {
              setUnivErrors(false)
            }
          } else {
            if (data.is_duplicate == "YES") {
              setUnivErrors(true)
            } else {
              setUnivErrors(false)
            }
          }
        }
      });
  };

  const onSubmit = (data, id, pageId, progressPercent) => {
    //For deleting unwanted data, send while save for latter
    if (commonLayoutData?.other_reference_id) {
      delete commonLayoutData?.other_reference_text
    }
    if (commonLayoutData?.other_reference_text) {
      delete commonLayoutData?.other_reference_id
    }
    if (commonLayoutData?.references) {
      if (typeof commonLayoutData?.other_reference_id !== 'undefined' || typeof commonLayoutData?.other_reference_text !== 'undefined') {
        delete commonLayoutData?.other_reference_id
        delete commonLayoutData?.other_reference_text
      }
    }
    if (data["university_email"] != data["confirm_university_email"]) {
      setError("University email should be same");
      return;
    }
    if (data["email"] !== data["confirm_email"]) {
      setError("Personal email should be same");
      return;
    }
    if (new Date(data["date_of_birth"]) > new Date()) {
      setError("Please select valid date of birth");
      return;
    }
    if (data.not_at_university === "false" && univErrors) {
      setError(`It seems like we already have an application from ${data.university_email} in our system, please contact smn@upreach.org.uk if you have any questions about your application.`)
      return;
    } else {
      setUnivErrors(false)
      setError("")
    }
    if (notAtUniversityYet == false) {
      var array = [
        "ac.uk",
        "upreach.org.uk",
        "london.edu",
        "pearsoncollege.com",
        "bpp.com",
        "ucfbstudent.com",
      ];
      let is_valid = true;
      array.forEach(domain => {
        if (data.university_email.match(domain)) {
          is_valid = false;
          return !is_valid;
        }
      });
      if (is_valid) {
        setError(
          "Please check your university email address"
        );
        return;
      }
    }
    data.form1 = true;

    if (id == "NEXT") {
      (data.not_at_university === "false") ? data.not_at_university = false : data.not_at_university = true
      setCommonLayoutData({ ...commonLayoutData, ...data });
      if (!commonLayoutData.token) {
        setCommonLayoutData({ ...commonLayoutData, ...data });
      }
      setNavigation(notAtUniversityYet);
      if (notAtUniversityYet) {
        setStep(3);
        setProgressValue("50");
      } else {
        setErr("");
        setStep(2);
        setProgressValue("20");
      }
    } else {
      data = { ...data, ...commonLayoutData };
      //Save for later move to last updated page
      if (commonLayoutData.page_id > pageId) {
        data.page_id = commonLayoutData.page_id;
        data.progress_percentage = commonLayoutData.progress_percentage;
      }
      else {
        data.page_id = pageId;
        data.progress_percentage = progressPercent;
      }
      partialDataStore({ data });
    }
  };
  return (
    <div>
      <h2 className="na-head mb-2">Personal Details</h2>
      <p className="mb-5">Sign up to the Social Mobility Network to gain access to hundreds of industry professionals who are ready to support you with your career development. This short application form will take you around 5 minutes to complete, and you do not need to prepare any information in advance.</p>
      <h4>BASIC INFORMATION</h4>
      <Form>
        <Form.Label>
          First Name <span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          defaultValue={
            commonLayoutData !== null ? commonLayoutData.first_name : ""
          }
          type="text"
          ref={register({ required: true })}
          name="first_name"
          placeholder="Enter your first name"
        />
        {errors.first_name && (
          <p className="mb-0 text-danger font-weight-bold">Please enter your First Name</p>
        )}
        <Form.Label>
          Last Name <span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          defaultValue={
            commonLayoutData !== null ? commonLayoutData.last_name : ""
          }
          type="text"
          ref={register({ required: true })}
          name="last_name"
          placeholder="Enter your last name"
        />
        {errors.last_name && (
          <p className="mb-0 text-danger font-weight-bold">Please enter your Last Name</p>
        )}
        <Form.Label>
          Date of Birth <span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          type="date"
          ref={register({
            required: true,
            pattern: /^(19|20)\d\d-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
          })}
          placeholder="Enter your date of birth"
          name="date_of_birth"
          defaultValue={
            commonLayoutData !== null ? commonLayoutData.date_of_birth : ""
          }
          viewDate={"13-01-2000"}
          max={new Date().toISOString().split("T")[0]}
        />
        {errors.date_of_birth && (
          <p className="mb-0 text-danger font-weight-bold">Please enter your date of birth</p>
        )}
        <Form.Label>
          Are you a current UK university student? <span className="required-text">*</span>
        </Form.Label>
        <Form.Group>
          <Form.Check
            id="atUniversity"
            htmlFor="atUniversity"
            type="radio"
            label="I am currently a UK university student"
            ref={register({ required: true })}
            name="not_at_university"
            value={false}
            onClick={e => {
              setAtUniversity(true);
              setNotAtUniversityYet(false);
            }}
            checked={atUniversity}
          />
          <Form.Check
            id="notAtUniversity"
            type="radio"
            name="not_at_university"
            htmlFor="notAtUniversity"
            value={true}
            ref={register({ required: true })}
            label="I am not yet a UK university student but expect to be in the future"
            onClick={e => {                  // setValue("not_at_university", true)
              setNotAtUniversityYet(true);
              setAtUniversity(false);
            }}
            checked={notAtUniversityYet}
          />
          {errors.not_at_university && (
            <p className="mb-0 text-danger font-weight-bold">Required field</p>
          )}
        </Form.Group>

        <h4 className="mt-5 mb-0">CONTACT DETAILS</h4>
        {notAtUniversityYet && (
          <div>
            <Form.Label>
              Expected start date <span className="required-text">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              ref={register({ required: true })}
              name="opening_year_id"
            >
              <option value="" selected disabled>
                Select academic year you will start university
              </option>
              {openingYears &&
                openingYears.length > 0 &&
                openingYears.map(year => (
                  <option
                    value={year.id}
                    selected={
                      year.id === Number(commonLayoutData?.opening_year_id)
                    }
                  >
                    {year.name}
                  </option>
                ))}
            </Form.Control>
            {errors.opening_year_id && (
              <p className="mb-0 text-danger font-weight-bold">Please mention your expected start date</p>
            )}
          </div>
        )}

        {!notAtUniversityYet && atUniversity && (
          <div>
            <Form.Group controlId="formGridEmail">
              <Form.Label>
                University email address <span className="required-text">*</span>
              </Form.Label>
              <Form.Control
                ref={register({
                  required: true,
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
                })}
                name="university_email"
                type="email"
                onBlur={e => duplicateUniversityEmail(e.target.value)}
                defaultValue={
                  commonLayoutData !== null
                    ? commonLayoutData.university_email
                    : ""
                }
                placeholder="Enter your university email"
              />
              {errors.university_email && (
                <p className="mb-0 text-danger font-weight-bold">Please enter your university email address</p>
              )}
            </Form.Group>
            <Form.Group controlId="formGridEmail">
              <Form.Label>
                Confirm your university email address <span className="required-text">*</span>
              </Form.Label>
              <Form.Control
                defaultValue={
                  commonLayoutData !== null
                    ? commonLayoutData.confirm_university_email
                    : ""
                }
                ref={register({
                  required: true,
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
                })}
                name="confirm_university_email"
                type="email"
                placeholder="Confirm your university email"
              />
              {errors.confirm_university_email && (
                <p className="mb-0 text-danger font-weight-bold">Please confirm your university email address</p>
              )}
            </Form.Group>
          </div>
        )}

        <Form.Group controlId="formGridEmail">
          <Form.Label>
            Personal email address <span className="required-text">*</span>
          </Form.Label>
          <Form.Control
            defaultValue={
              commonLayoutData !== null ? commonLayoutData.email : ""
            }
            ref={register({
              required: true,
              pattern: /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            name="email"
            type="email"
            placeholder="Enter your personal email"
          />
          {errors.email && (
            <p className="mb-0 text-danger font-weight-bold">Please enter your personal email address</p>
          )}
        </Form.Group>

        <Form.Group controlId="formGridEmail">
          <Form.Label>
            Confirm your personal email address <span className="required-text">*</span>
          </Form.Label>
          <Form.Control
            defaultValue={
              commonLayoutData !== null ? commonLayoutData.confirm_email : ""
            }
            ref={register({
              required: true,
              pattern: /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            })}
            name="confirm_email"
            type="email"
            placeholder="Confirm your personal email"
          />
          {errors.confirm_email && (
            <p className="mb-0 text-danger font-weight-bold">Please confirm your personal email address</p>
          )}
        </Form.Group>

        <Form.Group controlId="formGridphone">
          <Form.Label>
            Phone number <span className="required-text">*</span>
          </Form.Label>
          <Form.Control
            defaultValue={
              commonLayoutData !== null ? commonLayoutData.phone : ""
            }
            ref={register({ required: true, pattern: /^[0]{1}[0-9]{10}$/ })}
            name="phone"
            type="number"
            placeholder="Enter your phone number"
          />
          {errors.phone && (
            <p className="mb-0 text-danger font-weight-bold">Please enter your phone number</p>
          )}
        </Form.Group>

        <Form.Label>
          How did you hear about upReach <span className="required-text">*</span>
        </Form.Label>
        <Form.Control
          ref={register({ required: true })}
          name="references"
          id="references"
          as="select"
          onChange={handleChange}
        >
          <option value="" selected disabled>
            Select from drop-down menu
          </option>
          {Object.values(references).map((x, value) => (
            <option key={value} value={x.id}
              selected={
                x.id === Number(commonLayoutData?.references)}>
              {x.name}
            </option>
          ))}
        </Form.Control>
        {errors.references && (
          <p className="mb-0 text-danger font-weight-bold">
            Please tell us how you heard about upReach
          </p>
        )}
        {(referList != undefined && referList.length > 0) &&
          <Form.Group>
            <Form.Label>
              Please specify how you heard about us <span className="required-text"> *</span>
            </Form.Label>
            <Form.Control
              as="select"
              id="other_reference_id"
              ref={register({ required: true })}
              name="other_reference_id"
              onChange={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }) }}
            >
              <option value="" selected >
                Select from drop-down menu
              </option>
              {referList.map(
                (x, index) =>
                (
                  <option value={x.id} key={index}
                    selected={Number(commonLayoutData?.other_reference_id) === x.id}
                  >{x.name}</option>
                )
              )}
            </Form.Control>
            {errors.other_reference_id && (
              <p className="mb-0 text-danger font-weight-bold">
                Please select a subcategory for how you heard about us
              </p>
            )}
          </Form.Group>
        }
        {error != "" ? <p className="fail-text">{error}</p> : <></>}
        {(selectedReference === 14) && (
          <div>
            <Form.Label>
              Please specify how you heard about us <span className="required-text"> *</span>
            </Form.Label>
            <Form.Control
              type="text"
              ref={register({ required: true })}
              name="other_reference_text"
              defaultValue={
                commonLayoutData?.other_reference_text
              }
            />
            {errors.other_reference_text && (
              <p className="mb-0 text-danger font-weight-bold">
                Please select a subcategory for how you heard about us
              </p>
            )}
          </div>
        )}
        {(selectedReference === 4) && (
          <div>
            <Form.Label>
              Please specify how you heard about us <span className="required-text"> *</span>
            </Form.Label>
            <Form.Control
              type="text"
              ref={register({ required: true })}
              name="other_reference_text"
              defaultValue={
                commonLayoutData.other_reference_text
              }
            />
            {errors.other_reference_text && (
              <p className="mb-0 text-danger font-weight-bold">
                Please select a subcategory for how you heard about us
              </p>
            )}
          </div>
        )}

        {error != "" ? <p className="fail-text">{error}</p> : <></>}
        <div className="row my-5">
          <div className="col-md-2 my-auto text-md-left">
            <button className="btn btn-light btn-md" onClick={() => window.location.href = '/apply-now'}>CANCEL</button>
          </div>
          <div className="col-md-10 text-md-right">
            <button className="btn btn-light btn-md" onClick={handleSubmit(data => onSubmit(data, "SAVE_FOR_LATER", 1, 0))}>SAVE FOR LATER</button>
            <button className="btn btn-primary btn-md ml-3" onClick={handleSubmit(data => onSubmit(data, "NEXT"))} >NEXT</button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ApplyNowForms;